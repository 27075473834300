import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { get, compact, last } from "lodash";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Pages from "../../components/Pages";
import { makeUrl, removePreloader } from "../../helpers";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { KindLink, KindIframe, MainPageRadio } from "../../components/Lists";
import { Share } from "../../widgets";
import { RADIO_CONTENT, RADIO_MAX_UPDATE } from "../../queries/queries.graphql";
import EmptyData from "../../components/EmptyData";

export const query = graphql`
	query radioDataQuery($slug: String!) {
		hasura {
			...RadioContent
		}
	}
`;

export default function RadioPage({ location, data }) {
	const radioListMap = {
		KindLink,
		KindIframe,
		MainPageRadio,
	};

	const [path] = useState(last(compact(location.pathname.split("/"))));

	const [radio, setRadio] = useState(get(data, "hasura.radio[0]", {}));

	const maxUpdated = new Date(get(data, "hasura.radio_aggregate.aggregate.max.updated_at", new Date()));

	const radioOptions = {
		fetchPolicy: "no-cache",
		variables: {
			location: path,
		},
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(RADIO_MAX_UPDATE, radioOptions);
	const [loadRadio, { loading: dataLoading, called, data: fetchData, error: dataError }] = useLazyQuery(RADIO_CONTENT, radioOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "radio_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadRadio();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load RadioMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (called && fetchData && !dataError) {
			setRadio(get(fetchData, "radio[0]", {}));
		}

		removePreloader(!dataLoading || dataError);
	}, [dataLoading]);

	const { titleFull, content_blocks, kind, main_image } = radio;

	const url = makeUrl.radio(radio);
	const imageSrc = get(get(main_image, "src", ""));

	const breadcrumbsData = {
		item: {
			title_full: "медиатека",
			slug: "media",
		},
	};

	return radio ? (
		<Pages entity={radio} url={url} >
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={titleFull} pageContext={breadcrumbsData} />
				</div>
			</ContentBlock>
			<ContentBlock key={"radioElement"}>
				<div className={"container"}>
					<TextBlock title={titleFull}>
						{kind && React.createElement(radioListMap[kind], { ...radio })}
					</TextBlock>
				</div>
			</ContentBlock>
			<ContentBlock key={"media-content"} mount={content_blocks && !!content_blocks.length}>
				<div className={"container"}>
					<Contents items={content_blocks} />
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleFull={titleFull} imageSrc={imageSrc} />
			</div>
		</Pages>
	) : (
		<EmptyData />
	);
}

RadioPage.propTypes = {
	location: PropTypes.object,
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

RadioPage.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
};
